@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	border-top: 3px solid #bd0000;
	background: #fff;
	padding: 0 0 8px;
	
	.header-inner {
		max-width: 1000px;
		margin: 0 auto;
		
		@media screen and (max-width: 1060px) {
			padding: 0 30px;
		}
		
		&__logo {
			display: block;
			width: 36.5%;
		}
	}

}


