@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: $textColor;
	color: $white;
	
	.footer-inner {
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 0 30px;
		display: flex;
		justify-content: space-between;
		font-size: $xs;
		
		@media screen and (max-width: 1060px) {
			padding: 0 30px 30px;
		}
		
		&__logo {
			width: 36.5%;
			
			&__link {
				display: block;
				margin: 0 0 5px;
			}
			
			&__txt {
			}
		}
		
		&__address {
			padding: 30px 0 0;
			
			&__txt {
				font-weight: bold;
			}
		}
	}
	
	.footer-copyright {
		border-top: 1px solid #5f5f5f;
		padding: 20px 0;
		font-size: $xxs;
		color: #d5d5d5;
		text-align: center;
	}
}