@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	/* --------------------------------------------------- */
	/* index-visual */
	/* --------------------------------------------------- */
	.index-visual {
		background: url("/common/img/index/bg_visual.jpg") center center no-repeat;
		background-size: cover;
		width: 100%;
		max-height: 530px;
		overflow: hidden;
		
		@media screen and (max-width: 1300px) {
			padding: 0 20px;
		}
		
		&__ttl {
			max-width: 1228px;
			margin: 0 auto;
			padding: 30px 0 0;
		}
	}
	
	/* --------------------------------------------------- */
	/* index-soudan */
	/* --------------------------------------------------- */
	.index-soudan {
		width: 100%;
		padding: 0 0 45px;
		background: url("/common/img/index/bg_soudan03.png") center bottom no-repeat;
		background-size: 74px;
		position: relative;
		z-index: 3;
		
		&__ttl {
			background: #d30101;
			position: relative;
			color: $white;
			font-size: 3.2rem;
			font-weight: bold;
			
			@media screen and (max-width: 930px) {
				font-size: 2.8rem;
			}
			
			&::after {
				top: 100%;
				left: 50%;
				border: solid transparent;
				content: '';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-top-color: #d30101;
				border-width: 30px;
				margin-left: -30px;
			}
			
			&__inner {
				position: relative;
				max-width: 1194px;
				margin: 0 auto;
				padding: 45px 0;
				text-align: center;
				
				@media screen and (max-width: 930px) {
					padding: 35px 0;
				}
				
				&::before {
					background: url("/common/img/index/bg_ttl_soudan01.png") center 0 no-repeat;
					background-size: cover;
					width: 171px;
					height: 219px;
					bottom: 0;
					left: 4.6%;
					content: '';
					display: inline-block;
					position: absolute;
					
					@media screen and (max-width: 1140px) {
						width: 130px;
						height: 166px;
						left: 15px;
					}
				}
				
				&::after {
					background: url("/common/img/index/bg_ttl_soudan02.png") center 0 no-repeat;
					background-size: cover;
					width: 236px;
					height: 238px;
					bottom: 0;
					right: 0;
					content: '';
					display: inline-block;
					position: absolute;
					
					@media screen and (max-width: 1140px) {
						width: 170px;
						height: 171px;
						right: -15px;
					}
				}
				
				&__ico {
					color: $textYellow;
					margin: 0 10px 0 0;
				}
			}
		}
		
		&__list {
			background: url("/common/img/index/bg_soudan01.jpg") center center no-repeat;
			background-size: cover;
			padding: 75px 0;
			
			&__inner {
				max-width: 1012px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				
				@media screen and (max-width: 1060px) {
					margin: 0 30px;
				}
				
				&__item {
					margin: 0 6px 12px;
					padding: 30px 15px;
					width: calc((100% - 49px) / 4 );
					border: 2px solid #252525;
					background: url("/common/img/index/bg_soudan02.jpg") 0 0 repeat;
					@include radius(10);
					color: $white;
					box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);
					text-align: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					
					&__number {
						width: 16%;
						margin: 0 auto 10px;
						min-height: 0.1px;
					}
					
					&__txt {
						font-size: 1.9rem;
						line-height: 1.4;
						
						&__yellow {
							font-weight: bold;
							color: $textYellow;
							font-size: 2.8rem;
						}
					}
					
					&__img {
						font-size: 6.0rem;
						line-height: 1.0;
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-syoukai */
	/* --------------------------------------------------- */
	.index-syoukai {
		margin: -45px 0 0;
		padding: 0 0 90px;
		position: relative;
		z-index: 2;
		background: url("/common/img/index/bg_syokai03.jpg") 0 0 repeat;
		background-size: 160px;
		width: 100%;
		
		@media screen and (max-width: 1060px) {
			padding: 0 30px 90px;
		}
		
		&::after {
			background: url("/common/img/index/bg_syokai01.png") bottom center no-repeat;
			background-size: cover;
			width: 2000px;
			height: 905px;
			bottom: 0;
			left: 50%;
			content: '';
			display: inline-block;
			position: absolute;
			z-index: 1;
			transform: translate(-50%, 0);
		}
		
		&__top {
			box-shadow: 0 10px 6px -6px rgba(0,0,0,0.1) inset;
			position: absolute;
			z-index: 4;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		&__inner {
			max-width: 1000px;
			margin: 0 auto;
			padding: 75px 0 0;
			position: relative;
			z-index: 4;
			
			&__ttl {
				&__ico {
					font-size: 3.2rem;
				}
				
				&__inner {
					&__line {
						background: linear-gradient(transparent 70%, $textYellow 70%);
						display: inline-block;
					}
					
					&__red {
						color: $baseColor;
					}
				}
			}
			
			&__list {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 -30px;
				
				&__item {
					margin: 0 30px 30px;
					padding: 0 35px;
					width: calc((100% - 121px) / 2 );
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;
					background: url("/common/img/index/bg_syokai02.png") 0 0 repeat;
					background-size: 4px;
					@include radius(5);
					box-shadow: 0 0 8px 3px rgba(0,0,0,0.1);
					
					@media screen and (max-width: 900px) {
						padding: 0 20px 0 35px;
					}
					
					&__txt {
						width: 70%;
						height: 100%;
						font-size: $l;
						font-weight: bold;
						line-height: 1.4;
						padding: 20px 10px;
						position: relative;
						background: url("/common/img/index/pic_syokai_sub01.png") center center no-repeat;
						background-size: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						
						@media screen and (max-width: 1060px) {
							br {
								display: none;
							}
						}
						
						&__red {
							color: #e11b1b;
							font-size: 2.5rem;
							line-height: 1.2;
						}
					}
					
					&__img {
						width: 27%;
						margin: 20px 0;
						border: 2px solid #febbbb;
					}
					
					&__ico {
						position: absolute;
						top: 50%;
						left: -15px;
						transform: translate(0, -50%);
						background: $baseColor;
						color: $white;
						width: 50px;
						height: 50px;
						@include radius(5);
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 4.0rem;
						line-height: 1.0;
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-entry */
	/* --------------------------------------------------- */
	.index-entry {
		position: relative;
		z-index: 3;
		
		&__ttl {
			background: #d30101;
			color: $white;
			font-size: 4.8rem;
			font-weight: bold;
			
			@media screen and (max-width: 930px) {
				font-size: 3.4rem;
			}
			
			&__inner {
				position: relative;
				max-width: 1134px;
				margin: 0 auto;
				padding: 30px 0;
				text-align: center;
				
				@media screen and (max-width: 930px) {
					padding: 25px 0;
				}
				
				&::before {
					background: url("/common/img/index/bg_ttl_entry01.png") center 0 no-repeat;
					background-size: cover;
					width: 205px;
					height: 253px;
					bottom: 0;
					left: 5.9%;
					content: '';
					display: inline-block;
					position: absolute;
					
					@media screen and (max-width: 1140px) {
						width: 150px;
						height: 185px;
						left: 35px;
					}
					
					@media screen and (max-width: 970px) {
						width: 120px;
						height: 148px;
						left: 35px;
					}
				}
				
				&::after {
					background: url("/common/img/index/bg_ttl_entry02.png") center 0 no-repeat;
					background-size: cover;
					width: 326px;
					height: 266px;
					bottom: 0;
					right: 0;
					content: '';
					display: inline-block;
					position: absolute;
					
					@media screen and (max-width: 1140px) {
						width: 240px;
						height: 196px;
						right: 0;
					}
					
					@media screen and (max-width: 970px) {
						width: 190px;
						height: 155px;
						right: 15px;
					}
				}
				
				&__comment {
					color: #ffe237;
					font-size: 2.5rem;
					line-height: 1.4;
					position: relative;
					margin: 0 0 5px;
					
					&::before ,
					&::after {
						font-family: 'icomoon';
						font-size: 3.0rem;
						line-height: 1.0;
					}
						
					&::before {
						content: "\e905";
						margin: 0 5px 0 0;
					}

					&::after {
						content: "\e906";
					}
				}
				
				&__block {
					display: flex;
					justify-content: center;
					align-items: center;
					
					&__label {
						background: $white;
						@include radius(5);
						color: #d30101;
						font-size: 2.1rem;
						margin: 0 20px 0 0;
						padding: 10px 25px 8px;
						display: inline-block;
					}
				}
			}
		}
		
		&__block {
			background: url("/common/img/index/bg_entry01.jpg") center center no-repeat;
			background-size: cover;
			position: relative;
			z-index: 2;
			
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url("/common/img/index/bg_entry02.png") 0 0 repeat;
				background-size: 2px;
				content: "";
				z-index: 1;
			}
			
			&__inner {
				max-width: 720px;
				margin: 0 auto;
				padding: 50px 0 65px;
				position: relative;
				z-index: 3;
				
				&__form {
					background: $white;
					@include radius(10);
					margin: 0 0 25px;
					padding: 45px;
					position: relative;
					height: 537px;
					
					&__block {
						position: relative;
						display: block;
						
						&__inner {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							
							&__table {
								width: 75%;
								margin: 0 auto 20px;

								th {
									width: 180px;
								}

								td {
								}

								.label-must {
									margin: 0 0 0 10px;
								}

								.pref ,
								.text ,
								.keiken {
									width: calc(100% - 5px);
								}

								.radio {
									width: calc((100% - 10px) / 2);
									min-width: auto !important;
								}
							}

							&__btn {
								text-align: center;

								.btn {
									width: 200px;
								}

								&--confirm {
									.btn {
									}
								}
							}
						}
					}
					
					&__ttl {
						position: relative;
						line-height: 1.4;
						margin: 0 0 15px;
						padding: 0 0 15px;
						font-size: $xxxl;
						font-weight: bold;
						text-align: center;

						&::before {
							position: absolute;
							bottom: 0;
							left: 50%;
							display: block;
							width: 50px;
							height: 3px;
							content: '';
							margin: 30px 0 0;
							background: #c00000;
						}

						&::after {
							position: absolute;
							bottom: 0;
							right: 50%;
							display: block;
							width: 50px;
							height: 3px;
							content: "";
							margin: 30px 0 0;
							background: $textYellow;
						}
					}
					
					&__txt {
						text-align: center;
					}
				}
				
				&__arrow {
					display: flex;
					justify-content: center;
					
					&__item {
						width: 50px;
						height: 10px;
						margin: 0 5px;
						
						&__link {
							width: 50px;
							height: 10px;
							display: block;
							background: $white;
							@include transition;
							
							&:hover ,
							&--hover {
								background: #d30101;
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-contact */
	/* --------------------------------------------------- */
	.index-contact {
		max-width: 1000px;
		margin: 0 auto;
		padding: 40px 0;
		display: flex;
		
		@media screen and (max-width: 1060px) {
			margin: 0 30px;
		}
		
		&__btn {
			width: calc((100% - 41px) / 2);
			margin: 0 10px;
			@include radius(10);
			
			&__line {
				display: block;
				@include radius(10);
				transition: 0.3s all;
				box-shadow: 0 3px 0 0 #007500;
				
				&__img {
					@include radius(10);
				}
				
				&:hover {
					box-shadow: none;
					transform: translate3d(0, 3px, 0);
				}
			}
						
			&__tel {
				box-shadow: 0 3px 0 0 #760101;
				display: block;
				@include radius(10);
				
				&__img {
					@include radius(10);
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-point */
	/* --------------------------------------------------- */
	.index-point {
		padding: 45px 0 80px;
		position: relative;
		z-index: 2;
		background: url("/common/img/index/bg_point03.png") 0 0 repeat;
		width: 100%;
		
		@media screen and (max-width: 1060px) {
			padding: 45px 30px 80px;
		}
		
		&::after {
			background: url("/common/img/index/bg_point01.png") top center no-repeat;
			background-size: cover;
			width: 2000px;
			height: 1508px;
			top: 0;
			left: 50%;
			content: '';
			display: inline-block;
			position: absolute;
			z-index: 1;
			transform: translate(-50%, 0);
		}
		
		&__inner {
			max-width: 1000px;
			margin: 0 auto;
			position: relative;
			z-index: 3;
			
			.ttl02 {
				text-shadow: 0 0 15px $white, 0 0 15px $white, 0 0 15px $white, 0 0 15px $white;
			}
			
			&__list {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -15px;
				
				&__item {
					margin: 0 15px 60px;
					padding: 0 30px 40px;
					width: calc((100% - 61px) / 2);
					@include radius(10);
					border: 2px solid #950000;
					background: url("/common/img/index/bg_point02.png") 0 0 repeat;
					color: $white;
					position: relative;
					box-shadow: 0 0 8px 3px rgba(0,0,0,0.1);
					
					&::after {
						bottom: 0;
						right: 0;
						content: '';
						display: inline-block;
						position: absolute;
						z-index: 1;
					}
					
					&.point01 {
						&::after {
							background: url("/common/img/index/bg_point_sub01.png") 0 0 no-repeat;
							background-size: cover;
							width: 136px;
							height: 138.5px;
						}
					}
					&.point02 {
						&::after {
							background: url("/common/img/index/bg_point_sub02.png") 0 0 no-repeat;
							background-size: cover;
							width: 142px;
							height: 142.5px;
						}
					}
					&.point03 {
						&::after {
							background: url("/common/img/index/bg_point_sub03.png") 0 0 no-repeat;
							background-size: cover;
							width: 136px;
							height: 140px;
						}
					}
					&.point04 {
						&::after {
							background: url("/common/img/index/bg_point_sub04.png") 0 0 no-repeat;
							background-size: cover;
							width: 136px;
							height: 138.5px;
						}
					}
					&.point05 {
						&::after {
							background: url("/common/img/index/bg_point_sub05.png") 0 0 no-repeat;
							background-size: cover;
							width: 138px;
							height: 136px;
						}
						margin: 0 15px;
					}
					&.point06 {
						&::after {
							background: url("/common/img/index/bg_point_sub06.png") 0 0 no-repeat;
							background-size: cover;
							width: 130px;
							height: 138px;
						}
						margin: 0 15px;
					}
					
					&__number {
						border: 2px solid #950000;
						background: $white;
						color: #950000;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						width: 90px;
						height: 90px;
						border-radius: 50%;
						margin: -27px auto 20px;
						font-weight: bold;
						
						&__point {
						}
						
						&__txt {
							font-size: 4.0rem;
							line-height: 1.0;
						}
					}
					
					&__ttl {
						color: $textYellow;
						font-size: $xxxl;
						font-weight: bold;
						line-height: 1.4;
						margin: 0 0 15px;
						text-align: center;
						
						@media screen and (max-width: 795px) {
							br {
								display: none;
							}
						}
					}
					
					&__img {
						border: 1px solid $white;
						margin: 0 0 30px;
					}
					
					&__txt {
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-voice */
	/* --------------------------------------------------- */
	.index-voice {
		border-top: 1px solid $borderColor;
		border-bottom: 1px solid $borderColor;
		padding: 60px 0;
		
		&__list {
			&__item {
				border-bottom: 1px solid $borderColor;
				padding: 35px 0;
				
				&:last-of-type {
					border-bottom: 0;
					padding: 35px 0 0;
				}
				
				&:first-of-type {
					padding: 0 0 35px;
				}				
				
				&__inner {
					max-width: 1000px;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					font-size: $s;
					
					@media screen and (max-width: 1060px) {
						margin: 0 30px;
					}
					
					&__img {
						width: 9%;
						text-align: center;
						
						&__old {
							margin: 15px 0 0;
						}
					}
					
					&__txt {
						width: 86%;
						
						&__ttl {
							font-weight: bold;
							font-size: $l;
							margin: 0 0 15px;
							
							&::before {
								font-family: 'icomoon';
								line-height: 1.0;
								content: "\e912";
								margin: 0 5px 0 0;
								color: $baseColor;
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-flow */
	/* --------------------------------------------------- */
	.index-flow {
		background: url("/common/img/index/bg_syokai03.jpg") 0 0 repeat;
		background-size: 160px;
		padding: 60px 0 70px;
		
		&__list {
			max-width: 1000px;
			margin: 0 auto;
			display: flex;
			
			@media screen and (max-width: 1060px) {
				margin: 0 30px;
			}
			
			&__item {
				width: 20%;
				border: 1px solid $borderColor;
				border-right: none;
				background: $white;
				padding: 0 20px 35px;
				
				&:last-of-type {
					border-right: 1px solid $borderColor;
				}
				
				&__step {
					margin: -15px auto 25px;
					max-width: 100px;
					background: $baseColor;
					text-align: center;
					padding: 0 10px;
					color: $white;
					font-weight: bold;
					@include radius(15);
				}
				
				&__ico {
					color: $baseColor;
					text-align: center;
					font-size: 5.0rem;
					line-height: 1.0;
					margin: 0 0 15px;
				}
				
				&__ttl {
					font-weight: bold;
					font-size: 1.9rem;
					margin: 0 0 15px;
					text-align: center;
					line-height: 1.4;
				}
			}
		}
	}
	
}