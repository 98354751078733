@charset "utf-8";
/* ============================================================ */
/* #complete */
/* ============================================================ */
#complete {
	.complete-inner {
		&__txt {
			text-align: center;
			margin: 0 0 40px;
		}
		
		&__btn {
			text-align: center;
			
			.btn {
				width: 230px;
			}
		}
	}
}
