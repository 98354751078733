@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	border-top: 3px solid #bd0000;
	background: #fff;
	padding: 0 15px 8px;
	
	.header-inner {
		&__logo {
			display: block;
			width: 80%;
			margin: 0 auto;
		}
	}

}
