@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	/* --------------------------------------------------- */
	/* index-visual */
	/* --------------------------------------------------- */
	.index-visual {
		background: url("/common/img/index/bg_visual_sp.jpg") center center no-repeat;
		background-size: cover;
		width: 100%;
		max-eight: 315px;
		overflow: hidden;
				
		&__ttl {
		}
	}
	
	/* --------------------------------------------------- */
	/* index-soudan */
	/* --------------------------------------------------- */
	.index-soudan {
		width: 100%;
		padding: 0 0 35px;
		position: relative;
		z-index: 3;
		background: url("/common/img/index/bg_soudan03.png") center bottom no-repeat;
		background-size: 74px;
		
		&__ttl {
			background: #d30101;
			position: relative;
			color: $white;
			font-size: $xl;
			font-weight: bold;
			line-height: 1.5;
						
			&::after {
				top: 100%;
				left: 50%;
				border: solid transparent;
				content: '';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-top-color: #d30101;
				border-width: 15px;
				margin-left: -15px;
			}
			
			&__inner {
				position: relative;
				padding: 15px;
				text-align: center;
								
				&__ico {
					color: $textYellow;
					margin: 0 5px 0 0;
				}
			}
		}
		
		&__list {
			background: #efefef;
			padding: 35px 15px;
			
			&__inner {								
				&__item {
					margin: 0 0 10px;
					padding: 15px;
					border: 2px solid #252525;
					background: url("/common/img/index/bg_soudan02.jpg") 0 0 repeat;
					@include radius(10);
					color: $white;
					box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);
					display: flex;
					justify-content: space-between;
					align-items: center;
					
					&:last-of-type {
						margin: 0;
					}
					
					&__number {
						width: 11%;
					}
					
					&__txt {
						width: 61%;
						margin: 0;
						line-height: 1.4;
						
						&__yellow {
							font-weight: bold;
							color: $textYellow;
							font-size: $xxxl;
						}
					}
					
					&__img {
						width: 20%;
						font-size: 4.4rem;
						line-height: 1.0;
						text-align: center;
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-syoukai */
	/* --------------------------------------------------- */
	.index-syoukai {
		margin: -35px 0 0;
		padding: 0 0 45px;
		position: relative;
		z-index: 2;
		background: url("/common/img/index/bg_syokai03.jpg") 0 0 repeat;
		background-size: 160px;
		width: 100%;
				
		&::after {
			background: url("/common/img/index/bg_syokai01.png") bottom center no-repeat;
			background-size: cover;
			width: 750px;
			height: 339px;
			bottom: 0;
			left: 50%;
			content: '';
			display: inline-block;
			position: absolute;
			z-index: 1;
			transform: translate(-50%, 0);
		}
		
		&__top {
			box-shadow: 0 10px 6px -6px rgba(0,0,0,0.1) inset;
			position: absolute;
			z-index: 4;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		&__inner {
			padding: 40px 15px 0;
			position: relative;
			z-index: 4;
			
			&__ttl {
				&__ico {
					font-size: 3.2rem;
				}
				
				&__inner {
					&__line {
						background: linear-gradient(transparent 70%, $textYellow 70%);
						display: inline-block;
					}
					
					&__red {
						color: $baseColor;
					}
				}
			}
			
			&__list {
				padding: 0 0 0 5px;
				
				&__item {
					margin: 0 0 15px;
					padding: 0 15px;
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;
					background: url("/common/img/index/bg_syokai02.png") 0 0 repeat;
					background-size: 4px;
					@include radius(5);
					box-shadow: 0 0 8px 3px rgba(0,0,0,0.1);
										
					&__txt {
						width: 70%;
						height: 100%;
						font-size: $m;
						font-weight: bold;
						line-height: 1.4;
						padding: 20px 5px 20px 25px;
						position: relative;
						background: url("/common/img/index/pic_syokai_sub01.png") center center no-repeat;
						background-size: contain;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
												
						&__red {
							color: #e11b1b;
							font-size: $xl;
							line-height: 1.2;
						}
					}
					
					&__img {
						width: 27%;
						margin: 20px 0;
						border: 2px solid #febbbb;
					}
					
					&__ico {
						position: absolute;
						top: 50%;
						left: -7px;
						transform: translate(0, -50%);
						background: $baseColor;
						color: $white;
						width: 35px;
						height: 35px;
						@include radius(5);
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: $xxxl;
						line-height: 1.0;
					}
				}
			}
		}
	}
		
	/* --------------------------------------------------- */
	/* index-entry */
	/* --------------------------------------------------- */
	.index-entry {
		position: relative;
		z-index: 3;
		
		&__ttl {
			background: #d30101;
			color: $white;
			font-size: $xl;
			font-weight: bold;
						
			&__inner {
				position: relative;
				padding: 15px 0;
				text-align: center;
				
				&__comment {
					color: #ffe237;
					font-size: $l;
					line-height: 1.4;
					position: relative;
					margin: 0 0 5px;
					
					&::before ,
					&::after {
						font-family: 'icomoon';
						font-size: $xxl;
						line-height: 1.0;
					}
						
					&::before {
						content: "\e905";
						margin: 0 5px 0 0;
					}

					&::after {
						content: "\e906";
					}
				}
				
				&__block {
					display: flex;
					justify-content: center;
					align-items: center;
					
					&__label {
						background: $white;
						@include radius(5);
						color: #d30101;
						font-size: $m;
						margin: 0 10px 0 0;
						padding: 5px 15px 4px;
						display: inline-block;
					}
				}
			}
		}
		
		&__block {
			background: url("/common/img/index/bg_entry01.jpg") center center no-repeat;
			background-size: cover;
			position: relative;
			z-index: 2;
			
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url("/common/img/index/bg_entry02.png") 0 0 repeat;
				background-size: 2px;
				content: "";
				z-index: 1;
			}
			
			&__inner {
				padding: 25px 15px;
				position: relative;
				z-index: 3;
				
				&__form {
					background: $white;
					@include radius(10);
					margin: 0 0 25px;
					padding: 20px;
					position: relative;
					
					&__block {
						position: relative;
						display: block;
						height: 480px;
						
						&__inner {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							
							&__table {
								margin: 0 0 20px;

								th {
									display: block;
									display: flex;
									justify-content: space-between;
								}

								td {
									display: block;
								}

								.label-must {
								}

								.pref ,
								.text ,
								.keiken {
									width: 100%;
								}

								.radio {
									width: calc((100% - 10px) / 2);
									min-width: auto !important;
								}
							}

							&__btn {
								text-align: center;

								.btn {
									width: 60%;
								}

								&--confirm {
									.btn {
										width: 70%;
									}
								}
							}
						}
					}
					
					&__ttl {
						position: relative;
						line-height: 1.4;
						margin: 0 0 10px;
						padding: 0 0 10px;
						font-size: $xl;
						font-weight: bold;
						text-align: center;

						&::before {
							position: absolute;
							bottom: 0;
							left: 50%;
							display: block;
							width: 50px;
							height: 3px;
							content: '';
							margin: 30px 0 0;
							background: #c00000;
						}

						&::after {
							position: absolute;
							bottom: 0;
							right: 50%;
							display: block;
							width: 50px;
							height: 3px;
							content: "";
							margin: 30px 0 0;
							background: $textYellow;
						}
					}
					
					&__txt {
						text-align: center;
					}
				}
				
				&__arrow {
					display: flex;
					justify-content: center;
					
					&__item {
						width: 30px;
						height: 8px;
						margin: 0 5px;
						
						&__link {
							width: 30px;
							height: 8px;
							display: block;
							background: $white;
							@include transition;
							
							&--hover {
								background: #d30101;
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-contact */
	/* --------------------------------------------------- */
	.index-contact {
		margin: 0 15px;
		padding: 20px 0;
				
		&__btn {
			margin: 0 0 15px;
			@include radius(10);
			
			&__line ,
			&__tel {
				display: block;
				@include radius(10);
				
				&__img {
					@include radius(10);
				}
			}
			
			&__line {
				box-shadow: 0 3px 0 0 #007500;
			}
			
			&__tel {
				box-shadow: 0 3px 0 0 #760101;
			}
		}
	}

	/* --------------------------------------------------- */
	/* index-point */
	/* --------------------------------------------------- */
	.index-point {
		padding: 25px 0 40px;
		position: relative;
		z-index: 2;
		background: url("/common/img/index/bg_point03.png") 0 0 repeat;
		width: 100%;
				
		&::after {
			background: url("/common/img/index/bg_point01.png") top center no-repeat;
			background-size: cover;
			width: 750px;
			height: 566px;
			top: 0;
			left: 50%;
			content: '';
			display: inline-block;
			position: absolute;
			z-index: 1;
			transform: translate(-50%, 0);
		}
		
		&__inner {
			margin: 0 15px;
			position: relative;
			z-index: 3;
			
			.ttl02 {
				text-shadow: 0 0 15px $white, 0 0 15px $white, 0 0 15px $white, 0 0 15px $white;
			}
			
			&__list {
				&__item {
					margin: 0 0 30px;
					padding: 0 15px 20px;
					width: 100%;
					@include radius(10);
					border: 2px solid #950000;
					background: url("/common/img/index/bg_point02.png") 0 0 repeat;
					color: $white;
					position: relative;
					box-shadow: 0 0 8px 3px rgba(0,0,0,0.1);
					
					&::after {
						bottom: 0;
						right: 0;
						content: '';
						display: inline-block;
						position: absolute;
						z-index: 1;
					}
					
					&.point01 {
						&::after {
							background: url("/common/img/index/bg_point_sub01.png") 0 0 no-repeat;
							background-size: cover;
							width: 136px;
							height: 138.5px;
						}
					}
					&.point02 {
						&::after {
							background: url("/common/img/index/bg_point_sub02.png") 0 0 no-repeat;
							background-size: cover;
							width: 142px;
							height: 142.5px;
						}
					}
					&.point03 {
						&::after {
							background: url("/common/img/index/bg_point_sub03.png") 0 0 no-repeat;
							background-size: cover;
							width: 136px;
							height: 140px;
						}
					}
					&.point04 {
						&::after {
							background: url("/common/img/index/bg_point_sub04.png") 0 0 no-repeat;
							background-size: cover;
							width: 136px;
							height: 138.5px;
						}
					}
					&.point05 {
						&::after {
							background: url("/common/img/index/bg_point_sub05.png") 0 0 no-repeat;
							background-size: cover;
							width: 138px;
							height: 136px;
						}
					}
					&.point06 {
						&::after {
							background: url("/common/img/index/bg_point_sub06.png") 0 0 no-repeat;
							background-size: cover;
							width: 130px;
							height: 138px;
						}
						margin: 0;
					}
					
					&__number {
						border: 2px solid #950000;
						background: $white;
						color: #950000;
						display: flex;
						flex-direction: column;
						justify-content: center;
						text-align: center;
						width: 70px;
						height: 70px;
						border-radius: 50%;
						margin: -15px auto 10px;
						padding: 2px 0 0;
						font-weight: bold;
						box-sizing: border-box;
						line-height: 1.4;
						
						&__point {
							font-size: $xs;
						}
						
						&__txt {
							font-size: $xxxl;
							line-height: 1.0;
						}
					}
					
					&__ttl {
						color: $textYellow;
						font-size: $l;
						font-weight: bold;
						line-height: 1.4;
						margin: 0 0 8px;
						text-align: center;						
					}
					
					&__img {
						border: 1px solid $white;
						margin: 0 0 15px;
					}
					
					&__txt {
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-voice */
	/* --------------------------------------------------- */
	.index-voice {
		border-top: 1px solid $borderColor;
		border-bottom: 1px solid $borderColor;
		padding: 30px 0;
		
		&__list {
			&__item {
				border-bottom: 1px solid $borderColor;
				padding: 20px 0;
				
				&:last-of-type {
					border-bottom: 0;
					padding: 20px 0 0;
				}
				
				&:first-of-type {
					padding: 0 0 20px;
				}				
				
				&__inner {
					margin: 0 15px;
					font-size: $s;
					
					&__img {
						text-align: center;
						margin: 0 0 10px;
						
						&__human {
							width: 20% !important;
							margin: 0 auto;
							display: block;
						}
						
						&__old {
							margin: 5px 0 0;
						}
					}
					
					&__txt {
						&__ttl {
							font-weight: bold;
							font-size: $m;
							margin: 0 0 7px;
							text-align: center;
							
							&::before {
								font-family: 'icomoon';
								line-height: 1.0;
								content: "\e912";
								margin: 0 5px 0 0;
								color: $baseColor;
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-flow */
	/* --------------------------------------------------- */
	.index-flow {
		background: url("/common/img/index/bg_syokai03.jpg") 0 0 repeat;
		background-size: 160px;
		padding: 30px 0;
		
		.ttl02 {
			margin: 0 0 35px;
		}
		
		&__list {
			margin: 0 15px;
						
			&__item {
				width: 100%;
				border: 1px solid $borderColor;
				border-bottom: none;
				background: $white;
				padding: 0 15px 30px;
				
				&:last-of-type {
					border-bottom: 1px solid $borderColor;
				}
				
				&__step {
					margin: -15px auto 20px;
					max-width: 100px;
					background: $baseColor;
					text-align: center;
					padding: 0 10px;
					color: $white;
					font-weight: bold;
					@include radius(15);
				}
				
				&__ico {
					color: $baseColor;
					text-align: center;
					font-size: 4.0rem;
					line-height: 1.0;
					margin: 0 0 7px;
				}
				
				&__ttl {
					font-weight: bold;
					font-size: 1.7rem;
					margin: 0 0 7px;
					text-align: center;
					line-height: 1.4;
				}
			}
		}
	}
}