@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: $textColor;
	color: $white;
		
	.footer-inner {
		padding: 0 15px 20px;
		font-size: $xs;

		&__logo {
			width: 80%;
			margin: 0 auto 15px;
			text-align: center;
			
			&__link {
				display: block;
				margin: 0 0 5px;
			}
			
			&__txt {
			}
		}
		
		&__address {
			text-align: center;
			
			&__txt {
				font-weight: bold;
			}
		}
	}
	
	.footer-copyright {
		border-top: 1px solid #5f5f5f;
		padding: 15px 0;
		font-size: $xxs;
		color: #d5d5d5;
		text-align: center;
	}
}