@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	&__inner {
		max-width: 1000px;
		margin: 0 auto 60px;
		
		@media screen and (max-width: 1060px) {
			margin: 0 30px 60px;
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	background: $baseColor;
	color: $white;
	font-size: 3.6rem;
	font-weight: bold;
	text-align: center;
	line-height: 1.2;
	margin: 0 0 55px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	.error & {
		margin: 0;
	}
}

.ttl02 {
	margin: 0 0 45px;
	text-align: center;
	font-size: 3.2rem;
	color: $textColor;
	font-weight: bold;

	&__ico {
		font-size: 2.5rem;
		line-height: 1.0;
		color: #c00000;
		margin: 0 0 5px;
		display: inline-block;
	}
	
	&__inner {
    position: relative;
    display: block;
		line-height: 1.4;
		padding: 0 0 20px;
		
		&::before {
			position: absolute;
			bottom: 0;
			left: 50%;
			display: block;
			width: 50px;
			height: 3px;
			content: '';
			margin: 30px 0 0;
			background: #c00000;
		}
		
		&::after {
			position: absolute;
			bottom: 0;
			right: 50%;
			display: block;
			width: 50px;
			height: 3px;
			content: "";
			margin: 30px 0 0;
			background: $textYellow;
		}
	}
	
	&--sm {
		font-size: 2.8rem;
	}
}

.ttl03 {
	margin: 0 0 20px;
	font-size: 2.6rem;
	font-weight: bold;
	line-height: 1.4;
}



/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -5px;
	 margin-right: -5px;

	 &> * {
		 margin-left: 5px;
		 margin-right: 5px;
		 width: calc((100% - 21px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #f9f9f9;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}



/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	
	th {
		font-weight: bold;
		font-size: $m;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 10px 10px 0;
	}
	
	td {
		padding: 10px 0 10px 10px;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 45px;
	margin: 0 5px;
	padding: 0 30px;
	color: $white;
	font-size: $l;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;
	@include radius(5);
	font-weight: bold;

	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--red {
		background: $baseColor;
		color: $white;
	}
	&--gray {
		background: $white;
		border: 1px solid $borderColor;
		color: #797979;
	}
	&--gray02 {
		cursor: default;
		pointer-events: none;
		background: $borderColor;
		color: $white;
	}

	&--lg {
		min-width: 300px;
		height: 60px;
		font-size: $m;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}




/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	padding: 2px 10px;
	background: $white;
	border: 1px solid #d30101;
	color: #d30101;
	font-size: $xs;
	font-weight: bold;
	text-align: center;
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] ,
input[type="email"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d9d9d9;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d9d9d9;
	color: $textColor;
	@include radius(0);
	font-size: $m;
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d9d9d9;
	font-size: $m;
}

input::-webkit-input-placeholder {
	color: #9c9c9c;
}

textarea::-webkit-input-placeholder {
	color: #9c9c9c;
}

input::-moz-placeholder {
	color: #9c9c9c;
}

textarea::-moz-placeholder {
	color: #9c9c9c;
}

input:-ms-input-placeholder {
  color: #9c9c9c;
}

textarea:-ms-input-placeholder {
  color: #9c9c9c;
}


/* radio
----------------------------------------------------------- */
.radio {
	border: 1px solid #d9d9d9;
	color: $textColor;
	font-size: $m;
	min-width: 150px;
	padding: 4px 9px;
	cursor: pointer;
	display: inline-block;
	box-sizing: border-box;
	
	input[type="radio"] {
		display: none;
	}
	
	&::before {
		content: "";
		display: inline-flex;
		width: 14px;
		height: 14px;
		margin: 5px 7px 0 0;
		border: 1px solid #d9d9d9;
	}
	
	&:hover {
		opacity: 0.7;
		cursor: pointer;
	}
	
	&.check {
		color: $white;
		background: #d30101;
		position: relative;
		
		&::before {
			content: "";
			background: url("/common/img/index/ico_check.png") center center no-repeat $white;
			background-size: 10px;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 80px;
	height: 25px;
	background: $baseColor;
	color: #fff;
	font-size: $xxs;
	text-align: center;
	line-height: 25px;
}


/* --------------------------------------------------- */
/* box-touroku */
/* --------------------------------------------------- */
.box-touroku {
	max-width: 1000px;
	margin: 0 auto;
	padding: 40px 0;

	@media screen and (max-width: 1060px) {
		margin: 0 30px;
	}
	
	&__inner {
		display: flex;
		margin: 0 -10px;
		
		&__btn {
			width: calc((100% - 41px) / 2);
			margin: 0 10px;
			@include radius(10);
			
			&__line ,
			&__touroku {
				display: block;
				@include radius(10);
				transition: 0.3s all;
				
				&__img {
					@include radius(10);
				}
				
				&:hover {
					box-shadow: none;
					transform: translate3d(0, 3px, 0);
				}
			}
			
			&__line {
				box-shadow: 0 3px 0 0 #007500;
			}
			
			&__touroku {
				box-shadow: 0 3px 0 0 #cdab0c;
			}
			
			&__tel {
				box-shadow: 0 3px 0 0 #760101;
				display: block;
				@include radius(10);

				&__img {
					@include radius(10);
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* エラーページ専用 */
/* --------------------------------------------------- */
.error {
	.error-inner {
		text-align: center;
		
		&__btn {
			margin: 40px 0 0;
			
			.btn {
				width: 250px;
				color: $white !important;
			}
		}
	}
}

