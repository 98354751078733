@charset "utf-8";
/* ============================================================ */
/* #complete */
/* ============================================================ */
#complete {
	
	footer {
		@media screen and (min-height: 812px) {
			position: absolute !important;
			width: 100% !important;
			bottom: 0 !important;
		}
	}
	
	.complete-inner {
		&__txt {
			margin: 0 0 20px;
		}
		
		&__btn {
			text-align: center;
			
			.btn {
				width: 60%;
			}
		}
	}
}

