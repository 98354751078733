@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	&__inner {
		margin: 0 15px 30px;
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	background: $baseColor;
	color: $white;
	font-size: $xxxl;
	font-weight: bold;
	text-align: center;
	line-height: 1.2;
	margin: 0 0 25px;
	height: 130px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	.error & {
		margin: 0;
	}
}

.ttl02 {
	margin: 0 0 25px;
	text-align: center;
	font-size: 2.1rem;
	color: $textColor;
	font-weight: bold;

	&__ico {
		font-size: 2.5rem;
		line-height: 1.0;
		color: #c00000;
		margin: 0 0 5px;
		display: inline-block;
	}
	
	&__inner {
    position: relative;
    display: block;
		line-height: 1.4;
		padding: 0 0 15px;
		
		&::before {
			position: absolute;
			bottom: 0;
			left: 50%;
			display: block;
			width: 50px;
			height: 3px;
			content: '';
			margin: 30px 0 0;
			background: #c00000;
		}
		
		&::after {
			position: absolute;
			bottom: 0;
			right: 50%;
			display: block;
			width: 50px;
			height: 3px;
			content: "";
			margin: 30px 0 0;
			background: $textYellow;
		}
	}
	
	&--sm {
		font-size: $xl;
	}
}


.ttl03 {
	margin: 0 0 15px;
	font-size: $xl;
	font-weight: bold;
	line-height: 1.4;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #f9f9f9;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	table-layout: fixed;
	font-size: 1.5rem;
	line-height: 1.6;

	th,
	td {
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	
	th {
		font-weight: bold;
		font-size: $m;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 0;
	}
	
	td {
		padding: 0 0 5px;
	}
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 50px;
	margin: 5px 0;
	padding: 0 30px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include radius(5);
	font-weight: bold;
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	&--red {
		background: $baseColor;
	}
	&--gray {
		background: $white;
		border: 1px solid $borderColor;
		color: #797979;
	}
	&--gray02 {
		cursor: default;
		pointer-events: none;
		background: $borderColor;
		color: $white;
	}
	
	&--lg {
		width: 100%;
		height: 50px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: $xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	padding: 2px 10px;
	background: $white;
	border: 1px solid #d30101;
	color: #d30101;
	font-size: $xxxs;
	font-weight: bold;
	text-align: center;
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea,
select {
	color: $textColor;
	font-size: $m;
}

input[type="text"] ,
input[type="email"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}

input::-webkit-input-placeholder {
	color: #9c9c9c;
}

textarea::-webkit-input-placeholder {
	color: #9c9c9c;
}

/* radio
----------------------------------------------------------- */
.radio {
	border: 1px solid #d9d9d9;
	color: $textColor;
	font-size: 1.5rem;
	min-width: 150px;
	padding: 8px 10px 13px;
	cursor: pointer;
	display: inline-block;
	box-sizing: border-box;
	line-height: 1.0;
	
	input[type="radio"] {
		display: none;
	}
	
	&::before {
		content: "";
		display: inline-flex;
		width: 14px;
		height: 14px;
		margin: 5px 7px 0 0;
		border: 1px solid #d9d9d9;
	}
	
	&:hover {
		opacity: 0.7;
		cursor: pointer;
	}
	
	&.check {
		color: $white;
		background: #d30101;
		position: relative;
		
		&::before {
			content: "";
			background: url("/common/img/index/ico_check.png") center center no-repeat $white;
			background-size: 10px;
		}
	}
	
	span {
		margin: -5px 0 0;
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 45px;
	padding: 2px 0;
	background: $baseColor;
	color: #fff;
	font-size: $xxxs;
	text-align: center;
}


/* --------------------------------------------------- */
/* box-touroku */
/* --------------------------------------------------- */
.box-touroku {
	margin: 0 15px;
	padding: 20px 0;
	
	&__inner {
		&__btn {
			margin: 0 0 15px;
			@include radius(10);
			
			&__line ,
			&__touroku ,
			&__tel {
				display: block;
				@include radius(10);
				
				&__img {
					@include radius(10);
				}
			}
			
			&__line {
				box-shadow: 0 3px 0 0 #007500;
			}
			
			&__touroku {
				box-shadow: 0 3px 0 0 #cdab0c;
			}
			
			&__tel {
				box-shadow: 0 3px 0 0 #760101;
			}
		}
	}
}


/* --------------------------------------------------- */
/* エラーページ専用 */
/* --------------------------------------------------- */
.error {
	.error-inner {
		&__btn {
			text-align: center;
			
			.btn {
				width: 60%;
			}
		}
	}
	
	footer {
		@media screen and (min-height: 667px) {
			position: absolute !important;
			width: 100% !important;
			bottom: 0 !important;
		}
	}
}


