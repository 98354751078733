@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
}

body {
}

.wrap {
	color: $textColor;
	font-size: 1.5rem;
	line-height: 1.8;
	font-family: 'メイリオ', Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;
	overflow: hidden;
}

.pc-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 25px;
	padding: 10px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}



/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 100;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba($baseColor, 0.9);
		color: $white;
		font-size: $xxxl;
		text-decoration: none;
		@include radius(4);
	}
}